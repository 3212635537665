import { graphql, compose } from "react-apollo";
import gql from "graphql-tag";
import { lifecycle, branch, renderNothing, withProps } from "recompose";

import { CartInformer } from "./CartInformer";
import ActionTypes from "@common/data/ActionTypes";
import Dispatcher from "@common/data/Flux";

const siteFragment = gql`
  fragment siteFragment on Site {
    id
    modulesConnection {
      edges {
        node {
          __typename
          ... on CartModule {
            id
            options {
              cartPageURL
            }
          }
        }
      }
    }
  }
`;

const GET_CART_MODULE = gql`
  query getCartModule {
    site {
      ...siteFragment
    }
  }
  ${siteFragment}
`;

const GET_CART_DATA = gql`
  query getCartData {
    cartData @client {
      count
    }
  }
`;

const UPDATE_CART_DATA = gql`
  mutation updateCartData($count: Int) {
    updateCartData(count: $count) @client {
      count
    }
  }
`;

const CartInformerWithSubscription = lifecycle({
  componentDidMount() {
    const subscriptionToken = Dispatcher.register(action => {
      switch (action.type) {
        case ActionTypes.CART_UPDATE: {
          const products = localStorage.getItem("inCart");
          if (!products) {
            this.props.updateCartData(0);
            return;
          }

          const keys = Object.keys(JSON.parse(products));
          this.props.updateCartData(keys.length);
          return null;
        }

        default:
          return null;
      }
    });

    this.setState({ subscriptionToken });
    Dispatcher.dispatch({ type: ActionTypes.CART_UPDATE });
  },
  componentWillUnmount() {
    Dispatcher.unregister(this.state.subscriptionToken);
  }
})(CartInformer);

const hideIfNoCartModule = hasNoData => branch(hasNoData, renderNothing);

const enhance = hideIfNoCartModule(({ site }) => {
  if (!site) {
    return true;
  }

  const cartModule = site.modulesConnection.edges.find(
    ({ node: element }) => element.__typename == "CartModule"
  );

  if (!cartModule) {
    return true;
  }

  return false;
});

const MappedCartInformer = withProps(({ site }) => {
  const cartModule = site.modulesConnection.edges.find(
    ({ node: element }) => element.__typename == "CartModule"
  );
  return {
    cartPageURL: cartModule.node.options.cartPageURL
  };
})(CartInformerWithSubscription);

const EnhancedCartInformer = enhance(MappedCartInformer);

export default compose(
  graphql(GET_CART_MODULE, {
    props: ({ data }) => ({ ...data })
  }),
  graphql(GET_CART_DATA, {
    props: ({ data }) => ({ ...data }),
    options: {
      ssr: false
    }
  }),
  graphql(UPDATE_CART_DATA, {
    props: ({ mutate }) => ({
      updateCartData: count => {
        mutate({ variables: { count } });
      }
    }),
    options: {
      ssr: false
    }
  })
)(EnhancedCartInformer);
