import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import styled from "react-emotion";
import { display, space } from "styled-system";

import { Button } from "@common/components/Button";
import StyledIcon from "@common/components/StyledIcon";

import CartIcon from "./assets/icons/cart.svg";
import Link from "@common/components/Link";

const ItemsCount = styled("div")`
  opacity: 0.7;
  ${display} ${space};
`;

const HackedButton = styled(Button)`
  vertical-align: top;
`;

const propTypes = {
  count: PropTypes.number,
  cartData: PropTypes.shape({
    count: PropTypes.number
  }),
  cartPageURL: PropTypes.string
};

const defaultProps = {
  cartData: {
    count: 0
  }
};

const CartInformer = ({ cartPageURL, cartData }) => {
  return (
    <Link href={cartPageURL}>
      <HackedButton
        display={["block", null, "inline-block"]}
        width={[1, null, "auto"]}
        px={[1, null, null, 2]}
        py={["20px", "20px", 1]}
        bg="#F7F052"
        fontSize={[1, 3, "11px", 0]}
        mx={[null, null, "12px", 2]}
        lineHeight={[1, 3, 1]}
        fontWeight={[2, 1, 2]}
        letterSpacing={3}
        color="#202C39"
        textAlign="center"
        hover={{
          background: "#F5EC21",
          color: "#000"
        }}
        focus={{
          background: "#F5EC21",
          color: "#000"
        }}
      >
        <StyledIcon width={[16, 24, 16]} height={[16, 24, 16]} mr={[1, 3, 1]}>
          <CartIcon />
        </StyledIcon>
        <FormattedMessage id="cart_informer.label" defaultMessage="Корзина" />
        <ItemsCount display="inline-block" ml={[1, 3, 1]} color="#202C39">
          {cartData.count}
        </ItemsCount>
      </HackedButton>
    </Link>
  );
};

CartInformer.propTypes = propTypes;
CartInformer.defaultProps = defaultProps;

export { CartInformer };
